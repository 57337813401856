body {
	background: url(@bg-img-main-complete) no-repeat scroll 0 113px transparent;
	background-color: white;
}

#container {
	padding-top: 0;
}

#header {
	color: white;
	a {
		color: inherit;
		text-decoration: underline;
	}
}


#content {
	margin-left: 175px;
	padding: 10px;
	min-height: 200px;

	#showMenuButton {
		position: absolute;
		left: 0;
		z-index: 1000;

		a.btn {
			border-left: none;
			border-radius: 0 @border-radius-small @border-radius-small 0;
		}
	}

	&.content-login {
		width: 760px;
	}

	div.actions {
		padding: 0;
		margin: 0 0 10px;
		font-size: 12px;
		width: 100%;

		h3 {
			display: none;
		}
		ul li a {
			float: left;
			clear: none;
			margin-right: 5px;
		}
	}

	h1 {
		// we do some special positioning for our h1s
		position: absolute;
		left: 264px;
		top: 66px;
		color: white;
		text-shadow: 1px 1px 5px #24581a;
	}

	&.wide {
		margin-left: 0;
	}

	&.content-login {
		padding: 0;
		background: none;
	}

}

.pagination {
	margin: 0;
}

.transparent-box {
	background: rgba(255, 255, 255, 0.87);

    .box-shadow(2px 2px 9px #666);

	/* IE */
	zoom: 1;
}

.content-login fieldset {
	padding: 16px 20px;
}

.alert.message {
	border: none;
	border-radius: unset;

}

a {
	text-decoration: none;
}

#oder {
	text-align: center;
	p {
		font-weight: bold; 
		color: white; 
		font-size: 24px !important;
		text-shadow: 2px 2px 5px #24581a;
	}
}


#links {
	position: absolute;
	width: 155px;
	margin-left: 10px;
	padding: 10px 5px 0 0;
	background: rgba(255, 255, 255, 0.625);

	h3 {
		height: 26px;
		margin-top: 0;
		margin-bottom: 10px;
		padding-top: 0;
		padding-left: 43px;

		#hideMenuButton{
			float: right;
		}
	}

	.textButtons a {
		white-space: normal;
	}
	&.popup {
		background-color: #ddd;
		z-index: 100;
	}
	.picButtons {
		.btn-group {
			float: right;
			margin-left: 5px;
		}
	}
	.picButtons a {
		// clear: none;
		// float: right;
		// margin-left: 2px;
		// width: 24px;
	}
	li {
		text-align: right;
		line-height: 22px;
		margin-bottom: 2px;
	}
}

// #links .picButtons a img, #links .picButtons a, #content td.actions a img, #content td.actions a, a.picButton img, a.picButton {
	// width: 18px;
	// height: 18px;
// }

div.actions {
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
	}
}

a.btn-primary {
	.fa {
		// we need to override this; is white from velocity
		color: @btn-primary-color;
	}
}
.superscriptEditor {
	.cke_contents {
	}

	.superscriptDesc {
		margin-left: @grid-half-gutter-width;
		padding: @padding-base-vertical @padding-base-horizontal;
		width: 95%;
	}
}

.form-group {
	margin-bottom: @form-group-margin-bottom;

	label {
		margin-bottom: @label-margin-bottom;
	}
}

.filter.tab-pane {

}

/***** invites tree ***/
#invitesTree {
	li, ul {
		margin: 0;
		padding: 0;
	}

	ul {
		// margin-top: 6px;
		padding-left: 0;
	}

	li {
		// padding: 6px 0;
		list-style-type: none;

		& > ul {

		   	.col1 {
				padding-left: 2em;
			}
		}
	}

	.row {
		border-bottom: 1px solid #DDDDDD;
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.col1, .col2, .col3, .col4, .col5, .col6, .col7 {
		display: inline-block;
	}

	.col1{
		// width: 15em;
	}

	.col2{
		width: 10em;
	}

	.col3{
		width: 8em;
	}

	.col4{
		width: 10em;
	}

	.col5{
		width: 10em;
	}

	.col6{
		width: 15em;
	}

	.col7{
		width: 8em;
	}

	.bokuButton {
		width: 6em;
		display: inline-block;
	}

	.header {
		color: #003D4C;
		font-weight: bold;
		height: inherit;
		width: auto;
	}

	.statusAttends, .statusAttendsNot, .statusUnknown {
		width: 9em;
		text-align: center;
		white-space: nowrap;
	}

	.statusAttends{
		background-color: green;
		color: white;
	}

	.statusAttendsNot{
		background-color: red;
		color: white;
	}

	.statusUnknown{
		background-color: yellow;
	}
}

