/********* our mixins **********/
.standard-title {
	font-size: 36px;
	font-weight: 300;
	margin-top: 0;
}


// margin mixins

.margin(@orientation, @amount){
	margin-@{orientation}: @amount !important;
}
.margin-set(@orientation){
	&0 { .margin(@orientation, 0); }
	&1 { .margin(@orientation, 1px); }
	&5 { .margin(@orientation, 5px); }
	&7 { .margin(@orientation, 7px); }
	&10 { .margin(@orientation, 10px); }
	&13 { .margin(@orientation, 13px); }
	&15 { .margin(@orientation, 15px); }
	&17 { .margin(@orientation, 17px); }
	&25 { .margin(@orientation, 25px); }
	&30 { .margin(@orientation, 30px); }
}

/** margin classes */
.left {
	.margin-set(left);
}
.right {
	.margin-set(right);
}
.top {
	.margin-set(top);
}
.bottom {
	.margin-set(bottom);
}



/*************** homepage - videos **********************/
.video-container {
	.video-js {
		.vjs-tech {
			height: auto;
			position: inherit;
		}
	}
}

.modal.modal-video {
	height: 100%;

	.modal-dialog {
		height: 80%;
		max-width: 800px;
		width: auto;

		.modal-content {
			height: 100%;

			.modal-body {
				height: 100%;
				position: relative;

				.video-container {
					max-height: 450px;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;

					& .video-js {
						max-width: 800px;

						& .vjs-fullscreen {
							max-width: none;
						}
					}

					&, & video, & .video-js {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}
}

// Hide the control bar when video has ended
.vjs-default-skin.has-ended .vjs-control-bar {
  display: block;
  visibility: hidden;
  opacity: 0;

  @trans: visibility 1.0s, opacity 1.0s;
  .transition(@trans);
}


.video-endcard {
	max-width: 800px;
	padding: 4px 4px 0;
	background-color: fade(@darker-grey, 65%);
	
	.row {
		margin-left: -2px;
		margin-right: -2px;
		padding-left: 1px;
		
		.col-sm-4, .col-xs-12 {
			padding-left: 2px;
			padding-right: 2px;
			margin-bottom: 4px;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.video-thumb {
		@bg-color: fade(@color, 65%);
		
		position: relative;
		cursor: pointer;
		max-width: 260px;
		margin-left: auto;
		margin-right: auto;
		
		.thumb-caption {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			color: @text-color;
			background-color: @bg-color;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 0 10px;
			overflow: hidden;
			font-size: 14px;
			text-align: center;
			
			.transition(all 0.4s);
		}

		.vjs-big-play-button {
			display: block;
			opacity: 0;
			.transition(all 0.4s);
			
		}
	
		&:hover {
			.thumb-caption {
				background-color: @color;
				overflow: auto;
				white-space: normal;
				color: white;
			}
			
			.vjs-big-play-button {
				opacity: 1;
			}
		}
	}
	
	&.template {
		display: none;
	}
}


/*************** homepage - index bg slider ***********************/
.bg-slider-wrapper {
	.flexslider {
		.slide {
			&.slide-1 {
				background: @color-2 url(@bg-img-slider) no-repeat 50% top;
                .background-size(cover);
			}
		}
	}
}

/********* homepage - override velocity header ***********/
.header {
	h1.logo {
		a {
			max-width: 300px;
			white-space: nowrap;
		}

		img {
			float: left;
		    margin-bottom: -5px;
		    margin-right: 5px;
		    margin-top: -4px;
		}
	}
}

/************ homepage - footer ***************/
.footer {
	.adr {
		i {
			// icons have different sizes
			min-width: 20px;
		}
	}
}


/********* homepage - content title ********/
.container, .container-fluid {
	.content {
		h1 {
			.standard-title;
		}
	}
}

/**************** homepage - login modal ************/
.modal-login {
	.modal-header {
		border-bottom: none;
	}

	.title {
		&:extend(.why .title);
	}

	.lead {
		text-align: center;
	}
}

/*************** homepage - play button needs this when 'a' instead of 'button' **********/
.why {
	.control {
		.play-trigger {
			display: block;
			padding-top: 13px;
		}
	}
}

/*************** homepage - for nuggetz ************/
.nuggetz-control {
    background-color: #777;
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 5px;
}


/*************** app - container ***************/
#container {
	padding-top: 15px;
}

/*************** app - footer stuff ************/
html {
	height: 100%;
}

body.flushing-footer {
	height: 100%;


	#container {
		min-height: 100%;
		padding-bottom: 55px;
	}


	.footer-info {
		height: 40px;
		margin-top: -40px;


		/** with flushing-footer we want a shadow INTO the footer */
		.footer-inner {
		    position: relative;
		}
		.footer-content {
		    padding-top: 10px;
		}
		.footer-shadow {
		    border-bottom: 1px solid darkgray;
		    .box-shadow(0 15px 10px -4px rgba(0, 0, 0, 0.5));
		    height: 20px;
		    position: absolute;
		    top: -20px;
		    width: 100%;
		}
	}
}


.footer-info {
	margin-top: 15px;

	a {
		text-decoration: underline;
	}
	.footer-content {
		padding-top: 5px;
		padding-bottom: 5px;
	}


	&.navbar-fixed-bottom {
	    .box-shadow(0 -2px 10px 0 rgba(0, 0, 0, 0.5));


		&.light95 {
			background-image: url(@bg-img-footer-light95);
		    border-radius: 4px;
		    border-style: solid;
		    border-color: #aaa;
		    border-width: 1px 1px 0px 1px;
		    margin: 15px 30px -5px;
		    padding-bottom: 5px;
		}
	}


	&.dark100 {
		border-top: 1px solid #555;
		background-image: url(@bg-img-footer-dark100);


		a, a:hover, a:focus {
			color: #B6C6D6;
		}
	}
}


/*************** app - navbar ************/
@color-highlight: lighten(@navbar-default-bg, 20%);

.navbar-form .form-control {
	width: auto;
}
.navbar-evmgr {
	.navbar-collapse {
		&.collapse {
			text-align: inherit;
		}


		&.collapse.in, &.collapsing {
			background-color: @color;
			margin-left: 0;


			.dropdown-split-right {
			    display: none;
			}

		}
	}
}

.navbar.navbar-evmgr {
	max-height: @navbar-height;

	.navbar-form:last-child {
	    padding-right: 0;
	}

	@media (max-width: @grid-float-breakpoint-max) {
	    .navbar-collapse {
	        top: @navbar-height;
	    }


	    .navbar-form {
			.box-shadow(none);
	    }
	}


	background-color: @navbar-default-bg;

	.nav {
		> li {
			> a {
				color: @navbar-evmgr-link-color;
				font-weight: bold;

				&:hover, &:focus {
					background-color: @navbar-evmgr-highlight-bg;
				}
			}


			&.active {
				background-color: @navbar-evmgr-highlight-bg;
			}
		}
	}
}
.navbar-header {

	a.navbar-brand {
		&:extend(.header h1.logo);
		&:extend(.header h1.logo a);


		color: white;
		padding: 9px 15px;
		max-width: none;
	}


	img {
		&:extend(.header h1.logo img);
	}
}


/*********** app - dropdown-submenu does not exist in bootstrap3 *********/
.dropdown-submenu {
	position: relative;
	
	& > .dropdown-menu {
		top: 0;
		left: 100%;
		margin-top: -6px;
		margin-left: -1px;
		-webkit-border-radius: 0 6px 6px 6px;
		-moz-border-radius: 0 6px 6px 6px;
		border-radius: 0 6px 6px 6px;
	}

	&:hover > .dropdown-menu {
		display: block;
	}

	& > a:after {
		display: block;
		content: " ";
		float: right;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-width: 5px 0 5px 5px;
		border-left-color: #cccccc;
		margin-top: 5px;
		margin-right: -10px;
	}

	&:hover > a:after {
		border-left-color: #ffffff;
	}

	&.pull-left {
		float: none !important;
		
		& > .dropdown-menu {
			left: -95%;
			width: 100%;
			-webkit-border-radius: 6px 0 6px 6px;
			-moz-border-radius: 6px 0 6px 6px;
			border-radius: 6px 0 6px 6px;
		}
	}
}


/*********** app - we don't want shadow *********/
.btn-group.open .dropdown-toggle {
	.box-shadow(none);
}
.btn, a.btn {
	.fa {
		// we override velocity template's icon margin
		margin-right: 0;
	}
	&.btn-default .fa {
		color: @btn-default-color;
	}
}


/*********** app - filter ***************/
// adapted from Bootstrap's .make-grid-columns() (grid-framework.less)
.narrow-grid-columns(@parent) {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~"@{parent} .col-xs-@{index}, @{parent} .col-sm-@{index}, @{parent} .col-md-@{index}, @{parent} .col-lg-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~"@{parent} .col-xs-@{index}, @{parent} .col-sm-@{index}, @{parent} .col-md-@{index}, @{parent} .col-lg-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      padding-left:  5px;
      padding-right: 5px;
    }
  }
  .col(1); // kickstart it
}

@filter-bg: #f9f9f9;
#filterTabs {
	li.active {
		border-bottom-color: @filter-bg;
	}

	a {
    	padding: 10px;
    	white-space: nowrap;
    }

	display: block;
    border-right: 1px solid @divider;
}
.filter-panes, #filterSummary, #filter-statistics {
	border-color: @divider;
	border-style: solid;
	border-width: 1px;
	padding: 3px 5px;
}
#filter-statistics {
	table {
		width: auto;

		th, td {
			width: auto;
			font-size: 90%;
			padding: 2px 5px;
		}
	}
}
.filter-panes {
	padding: @filter-pane-padding;
	clear: both;
	border-color: @divider;
}
.filter-form .nav-tabs li {
	margin-bottom: -1px;
	width: auto;
}
.filter.tab-pane {
	label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.row {
		margin-left: -5px;
		margin-right: -5px;
	}

	.form-group {
		margin-bottom: 5px;
	}

}
.narrow-grid-columns(~".filter.tab-pane");


/********** app - some color changes ***********/
.form-control {
	// override velocity hardcoded height
	height: @input-height-base;
}

.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px fade(@color, 60%);
}

/************ app - select-auto-size for smaller selects, eg. for separated date ********/
.select-auto-size {
	select {
		width: auto;
		display: inline-block;
	}
}

.dropdown-menu {
	li {
		button:hover, button:focus, a:hover, a:focus {
			background-color: @color-highlight;
		}

		button {
			border: none;
			background-color: transparent;

			// copied from bootstrap
			display: block;
			padding: 3px 20px;
			clear: both;
			font-weight: normal;
			line-height: @line-height-base;
			color: @dropdown-link-color;
			white-space: nowrap; // prevent links from randomly breaking onto new lines
		}
	}
}

.superscriptEditor {
	/* for .superscriptDesc positioning */
	position: relative;

	.cke_contents {
		max-height: @line-height-max;
		border: none;
	}
	
	.cke_chrome {
		&:extend(.form-control);
		
		width: auto;
		
		&.cke_focus {
			&:extend(.form-control:focus);
		}
	}
	
	.cke_reset {
		/* override box-sizing of CKEditor */
		box-sizing: border-box;
	}
	
	.cke_top {
		display: none;
	}

	textarea {
		height: 26px;
	}

	// ***************** signup (?) ***************

	.btn {
		.fa {
			margin-right: 0;
		}
	}
}

.superscriptDesc {
	font-style: italic;
	font-size: 9px;
	display: block;
	text-align: right;
	border-top: 1px solid #ccc;
	position: absolute;
	
	.btn {
		float: right;
		margin-left: 10px;
	}
}


/****************** app - container *******************/

body.signup {
	@media (min-width: @grid-float-breakpoint) {
		> .container-fluid {
		    margin: 15px;
			margin-right: auto;
			margin-left: auto;
			max-width: 1000px; /* or 950px */
		}
	}
	
	> .container-fluid {
		padding-top: 15px;
		padding-bottom: 15px;
//		overflow-x: hidden;
	}
}

/****************** app - header **********************/
.header-signup {
	margin-bottom: 15px;
//	margin-left: -15px;
//    margin-right: -15px;
//    padding-left: 15px;
//    padding-right: 15px;
	// padding-bottom: 15px;
	box-shadow: 0 12px 15px -10px rgba(0, 0, 0, 0.25);

//	@media (min-width: @grid-float-breakpoint) {
		margin-left: -10000px;
		margin-right: -10000px;
		padding-left: 10000px;
		padding-right: 10000px;
		// position: fixed;
		// top: 0;

		> .row {
//			margin-left: 0px;
//			margin-right: 0px;
		}
//		.header-text {
//
//
//			h1 {
//				display: table-cell;
//				vertical-align: bottom;
//			}
//		}
//	}
	
	.header-logo, .header-text {
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.header-text {
		h1 {
			margin: 0;
		}

		@media (max-width: @grid-float-breakpoint-max) {
			.header-logo {
				img {
					float: none !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
			}
			h1 {
				text-align: center;
			}
		}
	}
}


.signup-logo2 {
	img {
		// so that it centers
		display: inline-block;
	}
}

.no-labels {
	label {
	    display: none;
	//    height: @input-height-base;
		// make padding same as .form-control
	  	padding: @padding-base-vertical @padding-base-horizontal;
	}
	
	.input.checkbox {
		label {
			display: block;
		}
	}
}


// used in older projects, works well together with col-* classes
.centered-block {
	&:extend(.center-block);
	float: none;
}




td.actions {
	.btn-group {
		white-space: nowrap;
		
		.btn {
			float:none;
		}
	}
}


fieldset {
	&.alert {
		display: block;
		padding: 0;
	}
}





/*************** app - flag panel ***************/
.flagCheckSet {
	white-space: nowrap;

	.logicalOperatorAnd {
		display: block;
	}
	
	label, span, input {
		display: inline;
		padding: 2px;
	}
}

#flag-filter .btn {
	margin-bottom: 3px;
}



.editable-inline.editable-empty, .empty-placeholder {
	font-style: italic;
	color: @gray-light;
}




.sums {
	table {
		th {
			text-align: right;
			padding: 0 5px 0 20px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
}



/************ app - card design *****************/

body.cards {
	#container {
		background-color: #f0f0f0;
	}
}

.card {
	background-color: white;

	// border-radius: @border-radius-small;
	// border-color: @card-border-light @card-border @card-border @card-border-light;
	// border-width: 1px;
	// border-style: solid;

	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.21);
	
	padding: @panel-body-padding;

	.logo {
		margin-bottom: 10px;
	}

	.btn-group-vertical {
		.btn {
			text-align: left;
		}
	}

	.table-dl {
		th {
			width: 23%;
		}
	}
	.card-actions {
		margin-top: 10px;

		.tags {
			position: relative;
			min-height: 22px;

			.tags-inner {
				bottom: 1px;
				position: absolute;
				right: 0;
			}
		}
	}
}


/************ app - button toggle checkboxes with fa check icon ******/
label.btn.toggle-checkbox {
	& > i.fa:before {
		content: "\f096";
	}

	&.active {
		& > i.fa:before {
			content: "\f046";
		}
		
		background-color: @btn-success-bg;
		border-color: @btn-success-border;
		color: @btn-success-color;
		/* text-shadow: 0 -1px 0 rgba(0,0,0,.2); */
	}
}

/******* app - addressPreview ******/
#addressPreview, .address-preview {
	&:extend(.form-control);
	height: auto;
}
#addressPreviewPopup, .address-preview-popup {
	&:extend(.form-control);
	height: auto;
	width: auto;
	// background-color: white;
	// border: 1px solid black;
	// padding: 4px;
	// color: black;
	display: none;
	position: absolute;
	z-index: 100;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.21);
}


#primaryAddressRadio {
	.radio {
		label {
			font-weight: bold;
		}
	}
}


.submit-top {
	.submit {
		@btn-border-width: 1px;
		margin-top: -(@actions-margin-bottom + 2*@btn-border-width + @line-height-computed + 2*@padding-base-vertical);
	}
}

.flags {
	.accordion-toggle {
		margin:0;
		padding: 5px;
		font-size: 14px;
		transition: 0.2s ease-in-out;

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}

		.fa-chevron-right {
			transition: 0.2s ease-in-out;
		}

		&[aria-expanded=true] {
			margin-bottom: 5px;

			.fa-chevron-right {
				transform: rotate(90deg);
			}
		}
	}

	.checkbox {
		margin: 0;

		.error-msg {
			margin: 10px 20px 0;
			display: block;
		}
	}
}


form {
	.show-boxes, &.show-boxes {
		.box {
			@box-color: lightblue;

			border: 1px solid @box-color;
			margin-bottom: 3px;

			& .box {
				border: 1px solid darken(@box-color, 20%);

				& .box {
					border: 1px solid darken(@box-color, 40%);
				}
			}
		}

		.row {
			margin-top: 3px;
			// XXX calculate:
			margin-left: -2px;
			margin-right: -2px;
		}
	}

	.show-elements, &.show-elements {
		.form-group {
			border: 1px solid blue;
		}
	}
}

.editable-click {
	border-bottom: none;
}

.editable-inline {
	.editableform {
		.combodate {
			select {
				display: inline-block;
			}
			white-space: nowrap;
		}
	}
}
.table-review {
	tr {
		td:nth-child(2) {
			min-width: 250px;
		}
	}

	th {
		vertical-align: top;
	}
}
#content-table {
	.selection {

		padding-top: 0;
		padding-bottom: 0;
		vertical-align: middle;
		width: 48px;
		min-width: 48px;

		.dropdown-toggle {
			padding-left: 4px;
			padding-right: 4px;
		}
	}
}

/**** app - view views ****/
.view {
	dl {
		dt, dd {
			// display: inline-block;
		}
	}
}

@import "checkbox-switch.less";



/**** app - review ****/
.review {
	legend {
		margin-bottom: 5px;
	}
}
.review-label {
	margin-bottom: 0;

	&.empty {
		color: @text-muted;
	}
}
.review-value {
	margin-bottom: 5px;

	&.empty {
		color: @text-muted;
	}
}

/**** Sortable ****/
.sortable-ghost {
	opacity: .5;
	background: #C8EBFB;
}

.index {
	.table {
		th {
			a.desc:after, a.asc:after {
				font-family: FontAwesome;
				padding-left: 2px;
			}

			a.asc:after {
				content: "\f0de";
			}

			a.desc:after {
				content: "\f0dd";
			}
		}
	}
}

/**
 * some timepicker tuning
 */
.timepicker {
	a.btn {
		&:hover {
			background-color: inherit;
		}

		.fa {
			color: @color;
		}
	}
}

/**
 * override velocity's appearance: none
 */
select {
	-webkit-appearance: auto !important;
	-moz-appearance: auto !important;
	appearance: auto !important;
}
