
mbody, p, td, input, textarea, div, label, legend, select {
	font-size: 12px;
}
.form fieldset {
	padding: 4px;
	margin-bottom: 4px;
}
#main {
	color: #222222;
}
div.form, div.index, div.view {
    width: 100%;
    padding: 0;
    border: none;
}
h1 {
	font-size: 120%;
	background: none;
}
h2 {
	background: none;
}
form {
	width: 100%;
	margin: 0;
}
#filterPanel {
	background-color: #DDDDDD;
}
#filterPanel legend {
	background: none;
}
#filterPanel fieldset fieldset {
	border: 1px solid #999999;
}
#filterPanel table {
	background: none;
	clear: none;
	width: 70%;
}
#filterPanel th {
	border: none;
	padding: 0;
}
#filterPanel div.input {
	margin:0;
	padding: 0;
}
.bokuButton {
	cursor: pointer;
	-moz-border-radius: 8px 8px 8px 8px;
    background: -moz-linear-gradient(center top , #F1F1D4, #E6E49F) repeat scroll 0 0 #E6E49F;
    border: 1px solid #AAAC62;
    color: #333333;
    font-weight: normal;
    min-width: 0;
    text-decoration: none;
    text-shadow: 0 1px 0 #FFFFFF;
    text-align: center;
}
#flagsToggle {
   width: 21em;
    clear: none;
}
table td.actions a {
	margin: 0 1px;
	font-size: 12px;
}
#invitePopup {
	background-color: #DDDDDD;
    border: 1px solid black;
    clear: none;
    display: none;
    position: absolute;
    right: 20px;
    z-index: 100;
}
#InviteRsvpForm #InviteWillAttend {
	/*clear: none;*/
}
#InviteRsvpForm label, #InviteRsvpForm div.input  {
	display: inline;
}
#InviteRsvpForm #InviteEntourage {
	width: 5em;
}
#header h1 {
	background-image: none;
	padding: 0;
	font-weight: bold;
	font-size: 1.5em;
}
#header #bokuLogo {
	float: left;
	margin-right: 10px;
	border: 5px solid white;
}
#header #userInfo, #header h1 {
	padding-top: 20px;
}
#header #userInfo {
	padding: 52px 20px;
    text-align: right;
    width: 935px;
    font-size: 12px !important;
}
#header {
	height: 113px;
	background: url(@bg-img-header) no-repeat;
	padding: 0;
}
#footer {
/*	background: url('../img/footer.jpg') repeat-x; */
	padding: 0;
	text-align: left;
	height: 18px;
}
.narrow #footer {
/*	background: url('../img/footer-narrow.jpg') no-repeat; */
}
#footer.wide img, .narrow #footer img {
	display: none;
}
fieldset.flags {
	vertical-align: top;
	font-size: 12px;
	clear: both;
	text-align: center;
}
fieldset.flags fieldset {
	display: inline;
	width: 30%;
	vertical-align: top;
	margin-right: 0.5%;
    padding: 0.5%;
    text-align: center;
}
fieldset.flags div.checkbox {
	display: inline;
	margin: 0;
}
fieldset.flags div.checkbox input {
	clear: none;
	float: none;
}
#filterButtons {
	float: right;
	clear: none;
}
.contacts form#filters {
	width: auto;
	margin-right: 0;
}
div#personalAddress {
	width: 66%;
}

.form fieldset div.input, .form fieldset div.date {
	display: inline;
	background-color: #DDDDDD;
	clear: none;
	float: left;
	padding: 2px;
	width: 31%;
	margin-right: 1%;
	margin-bottom: 0.5em;
	margin-left: 0px;
	/* min-height: 43px; */
}
.form fieldset div.input input {
	clear: none;
	width: 95%;
}
.form fieldset div.input select {
	font-size: 9px !important;
}
.form fieldset div.input input[type=checkbox] {
	width: auto;
}
input[type="submit"] {
	border-radius: 2px;
}
a.picButton {
    background: -moz-linear-gradient(center top , #F1F1D4, #E6E49F) repeat scroll 0 0 #E6E49F;
    border: 1px solid #AAAC62;
    border-radius: 8px 8px 8px 8px;
    color: #333333;
    font-weight: normal;
    min-width: 0;
    padding: 4px;
    text-decoration: none;
    text-shadow: 0 1px 0 #FFFFFF;
	display: inline-block;
}
.hidden {
	display: none !important;
}
.narrow #hideMenuButton {
	display: none;
}
dd {
	margin-left: 25em;
}
.form fieldset.dateBox {
	width: 99%;
	border-left: 0;
	border-right: 0;
	padding-left: 0;
	margin-bottom: 0;
}
#addressPreview {
	border: 1px solid #999;
	display: inline-block;
	padding: 0.5em;
}
span.cke_skin_kama {
	border: none !important;
	padding: 0px !important;
}
#overlayer {
	display:none;
	width: 80%;
	/* height: 80%; */
	z-index: 100;
	position: absolute;
	left: 10%;
	margin: auto;
	/* border: 10% solid rgba(0, 0, 0, 0.2); */
	border: 5px solid red;
	background-color: white;
}
.hideable {
	margin: 0;
	padding: 0;
	border: 0;
/*	display: none; */
}
.superscriptDesc {
	font-style: italic !important;
	font-size: 9px !important;

/*
	background-color: white;
    border: 1px solid #999999;
    bottom: 20px;
    display: inline-block;
    position: fixed;
    right: 20px;
    width: 30%;
    z-index: 100;
    */
}
#contactSecondary {
	padding: 0;
	margin: 0;
}
#overlayer .close {
	float: right;
	margin: 2em 0.5em;
    width: 8em;
    clear: both;
}
.subheader {
	font-weight: bold;
	margin: 0.1em;
}
#addressPreviewPopup {
	background-color: white;
	border: 1px solid black;
	padding: 4px;
	color: black;
	display: none;
	position: absolute;
	z-index: 100;
}
.clear {
	clear: both !important;
}
form div.submit {
	clear: none !important;
	margin-bottom: 0.2em;
	position: relative;
	top: -8px;
	text-align: right;
}
#ContactPersonalAddress {
	height: 2em;
	width: 96%;
}
#birthday-unknown {
	float: right;
}
.ajax_status {
	display: none;
}
.ajax_success {
	color: #036A0D;
}
.ajax_error {
	color: #FF0000;
}
.paging div {
	float: left;
}
.numbers {
	white-space: nowrap;
	overflow: hidden;
	display: inline;
	margin-left: 0.4em;
	margin-right: 0.4em;
}
.numbers #hint {
	color: #003D4C;
    font-weight: bold;
    text-decoration: underline;
}
.numbers #actual-numbers {
	display: block;
    margin-left: 0.4em;
}
.numbers:HOVER {
	width: auto;
	height: auto;
	white-space: normal;
}
.numbers:HOVER #actual-numbers {
	display: block;
}
div.paging span.disabled {
	display: none;
}
.view #addressPreviewContainer {
	float: right;
}
th {
	white-space: nowrap;
}
form#filters .required label:after {
	content: none;
}

.dp-disabled {
	display: none;
}

#flashMessage {
	.alert;
	.alert-danger;
	.alert.message;
}
