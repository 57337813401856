/**
 * some global vuejs stuff
 */
[v-cloak] {
		display:none
	& > * {
		// border: 20px solid red;
	}
	&::before {
		content: "loading…"
	}
}

/**
 * dashboard: cart icon pulse animation
 */
@keyframes pulse-success {
  0% {
    box-shadow: 0 0 0 0 fade(@btn-success-bg, 70%), 0 0 0 0px fade(@btn-success-bg, 0%);
  }

  25% {
    box-shadow: 0 0 0 25px fade(@btn-success-bg, 35%), 0 0 0 0px fade(@btn-success-bg, 70%);
  }

  50% {
    box-shadow: 0 0 0 50px fade(@btn-success-bg, 35%), 0 0 0 25px fade(@btn-success-bg, 70%);
  }

  75% {
    box-shadow: 0 0 0 50px fade(@btn-success-bg, 0%), 0 0 0 50px fade(@btn-success-bg, 35%);
  }

  100% {
    box-shadow: 0 0 0 50px fade(@btn-success-bg, 0%), 0 0 0 50px fade(@btn-success-bg, 0%);
  }
}
